import './App.css';
import React from 'react';
import Stories, { WithSeeMore } from 'react-insta-stories';

class App extends React.Component {
	componentDidMount() {
		if (window.location.pathname === "/linkedin") {
			window.location.href = 'https://www.linkedin.com/in/lex-van-zalingen-153a2a93/';
		}
	}
	render() {
		return (
			<div className="App">
				<div className="left">
					<h2>Hello there!</h2>
					<p>Check out my story, in stories.</p>
					<br />
					<div className="instructions" style={{ background: '#FAFAFA', padding: 16, borderRadius: 0, boxShadow: '0 0 20px #00000020', width: 'auto' }}>
						<p>◀ Tap left for previous story</p>
						<p>▶︎ Tap right for next story</p>
						<p>◉ Press and hold to pause</p></div>
				</div>
				<div className="stories">
					<Stories loop keyboardNavigation defaultInterval={8000} stories={stories} onStoryEnd={(s, st) => console.log('story ended', s, st)} onAllStoriesEnd={(s, st) => console.log('all stories ended', s, st)} onStoryStart={(s, st) => console.log('story started', s, st)} />
				</div>
			</div>
		);
	}
}

const stories = [
	{
		content: ({ action, isPaused }) => {
			return <div style={contentStyle}>
				<h1>I am <br /><span>Lex</span></h1>
				<p>I love the challenges in working on digital products, sports and the occasional coding. </p>
			</div>
		}
	},
	{
		content: ({ action, isPaused, story }) => {
			return (
			<WithSeeMore story={story} action={action}>
				<div style={{ background: '#666', padding: 20 }}>
					<h3 style={{ marginTop: '20%', marginBottom: 20, color: 'white' }}> I am a people-minded, entrepreneurial spirit. I've started a few initiatives myself, but now I own product in a scale-up and support my partner's business.</h3>
					<img style={{ marginTop: 20, width: '100%', boxShadow: '0 0 20px #00000036', color: 'white' }} alt="Mi Vida Store" src="./img/store-image.png" />
					<h3 style={{ marginTop: 20, color: 'white' }}>I love fast paced, growth-oriented environments. I thrive when pressure is high and enjoy a good challenge.</h3>
				</div>
			</WithSeeMore>
			)
		},
		seeMore: ({ close }) =>
		(
			<div style={{ maxWidth: '100%', height: '100%', padding: 40, paddingTop: 20, background: 'white', overflowY: 'scroll' }}>
				<h2>My traits</h2>
				<p style={{ textDecoration: 'none', position: 'absolute', right: '30px', top: '41px', fontSize: '1.6rem', lineHeight: '1rem' }} onClick={close}>x</p>
				<ul style={{marginTop: 38}}>
					<li><h3>Quick Learner</h3><p>Learned about complex technology in record time, able to add value quickly</p></li>
					<li><h3>Proactive</h3><p>I dig into data to inform company on potential strategic bets and talk to my team about potential improvements</p></li>
					<li><h3>People-minded</h3><p>I continuously talk to team members to see how they are feeling and how I can help them</p></li>
					<li><h3>Thorough</h3><p>I can manage complex projects from start to finish, with plenty of technical understanding and PMO skills</p></li>
				</ul>
			</div>
		)
	},
	{
		content: ({ action, isPaused }) => {
			return <div style={{ background: 'white', padding: 20, background: '#666' }}>
				<h2 style={{ marginTop: '55%', marginBottom: 20, color: 'white' }}>This is also me:</h2>
				<img style={{ marginTop: 5, width: '100%', color: 'white' }} alt="crossfit giphy" src="https://media.giphy.com/media/l2JhEcQlutYmzA9Fu/giphy-downsized.gif" />
				<h3 style={{ color: 'white' }}>Well... Close enough...</h3>
			</div>
		}
	},
	{
		content: ({ action, story }) => {
			return <WithSeeMore story={story} action={action}><div style={{ background: '#666', padding: 20 }}>
				<h2 style={{ marginTop: '30%', marginBottom: 20, color: 'white' }}>I became a strong Product Manager through:</h2>
				<h3 style={{ marginBottom: 20, color: 'white' }}><ul><li>Managing complex technical projects at Amberscript</li><li>Setting up a discovery and prioritisation process at Amberscript</li><li>Acting as an Advisory Board member for the Welcome foundation</li><li>Initiating a product-led development approach at Accenture</li></ul></h3>
			</div></WithSeeMore>
		},
		seeMore: ({ close }) =>
		(
			<div style={{ maxWidth: '100%', height: '100%', padding: 40, paddingTop: 20, background: 'white', overflowY: 'scroll' }}>
				<h2>Experience</h2>
				<p style={{ textDecoration: 'none', position: 'absolute', right: '30px', top: '41px', fontSize: '1.6rem', lineHeight: '1rem' }} onClick={close}>x</p>
				<div>
					<h3>Product Manager - Amberscript</h3>
					<p>I grew my Product Management skillset by setting up discovery-, prioritisation- and stakeholder management processes. As the first product hire, I had the opportunity to fully own product within a scale-up. I worked on preparation for and execution with three agile teams.</p>
				</div>
				<div>
					<h3>MSc Management - Open University</h3>
					<p>Learned vital management traits and skills as part of a MSc degree in management. Specifically tailored to risk- and bias management.</p>
				</div>
				<div>
					<h3>Business Analyst - Accenture Interactive</h3>
					<p>As a BA I've learned to work with product management and act as a PM. As the agency counterpart of a client product manager, I have been consulting on best-practices around prioritisation, roadmapping, working with agile teams and much more.</p>
				</div>
				<div style={{marginBottom: '15%'}}>
					<h3>Advisory Board Member - WelcomeApp</h3>
					<p>WelcomeApp is a startup that helps newcomers feel at home in their country of refuge. I've been coaching Welcome's Product Lead on data-informed product strategy, validation and working with development teams.</p>
				</div>
			</div>
		)
	},
	{
		content: ({ action, isPaused }) => {
			return (
				<div style={{ background: 'white', padding: 20, background: '#666' }}>
					<h2 style={{ marginTop: '28%', marginBottom: 20, color: 'white' }}>Key accomplishments:</h2>
					<ul style={{ marginTop: 20, color: 'white' }}>
						<li style={{ marginTop: 20 }}>Improved engineering planning and execution processes to increased transparency, higher output and lesser stress-levels.</li>
						<li style={{ marginTop: 20 }}>Set up KPI framework for product, led implementation and reporting. Spread KPI driven-mindset within team as a result.</li>
						<li style={{ marginTop: 20 }}>Coordinated and executed growth initiatives (research and execution) on app and web, resulting in higher MQL conversion rate (8%), increased transcriber performance (6%), higher email validation rate (30%) and higher payment rate (50%).</li>
					</ul>
				</div>
			)
		}
	},
	{
		content: ({ action, isPaused }) => {
			return <div style={{ background: '#333', padding: 20, width: '100%' }}>
				<h2 style={{ marginTop: '80%', marginBottom: 20, color: 'white' }}>Curious?</h2>
				<span style={{ color: 'white' }}>+31 6 13356089</span><br />
				<span style={{ color: 'white' }}>hello@lexvanzalingen.nl</span><br />
				<span style={{ color: 'white' }}>lexvanzalingen.nl/linkedin/</span>
			</div>
		}
	}
]

const contentStyle = {
	width: '100%',
	padding: 20,
	paddingTop: '20%',
	color: 'white',
	backgroundImage: 'url(./img/lex-story-poster.jpg)',
	backgroundSize: 'cover'
}

export default App;
